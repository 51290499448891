import moment from 'moment';

export const truncateString = (str, num) => {
    if (str.length > num) {
        return `${str.slice(0, num)}...`;
    }
    return str;
};

export const firstLetterUppercase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatDate = (date) => {
    return moment(date).format('D/M/Y');
};

export const subtractOneDay = (date: Date) => {
    return moment(date).subtract(1, 'day');
};

export const addOneDay = (date: Date) => {
    return moment(date).add(1, 'day');
};

export const calulateTotalAmount = (amount, discount, promoCodeDiscount) => {
    const y = amount / (1 - promoCodeDiscount / 100);
    const x = y / (1 - discount / 100);

    return x?.toFixed(2);
};

export const getNotificationTime = (date: Date) => {
    return moment(date).toNow(true);
};
